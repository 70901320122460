import { render, staticRenderFns } from "./AppUnauthorized.vue?vue&type=template&id=8531db12&"
import script from "./AppUnauthorized.vue?vue&type=script&lang=js&"
export * from "./AppUnauthorized.vue?vue&type=script&lang=js&"
import style0 from "./AppUnauthorized.vue?vue&type=style&index=0&id=8531db12&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports